import React, { useEffect } from 'react'
import { PageProps, navigate } from 'gatsby'
import { getRequest } from '../../services/api'
import { login } from '../../services/auth'

const parseToken = (path: string): [string, string] => {
  if (path && path.includes('/')) {
    return path.split('/') as [string, string]
  } else {
    return ['', '']
  }
}

export default function VerifyPage(props: PageProps) {
  const [userUuid, token] = parseToken(props.params['*'] || '')

  useEffect(() => {
    const callback = async () => {
      if (userUuid && token) {
        const response = await getRequest(
          `/auth/v1/verify/${userUuid}/${token}`
        )

        if (response) {
          if (response.code === 200) {
            login(response.data)
          }
        }

        navigate('/')
      } else {
        navigate('/')
      }
    }
    callback()
  })

  return <>{null}</>
}
